<template>
    <div class="filter-box">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>
        <div class="filter-items">
            <!-- SITES -->
            <div class="filter">
                <label>{{ $t('user.filters.search_by') }}</label>
                <v-select
                    :class="{
                        filtered: currentFilters.sites,
                        changed: JSON.stringify(currentFilters.sites) != JSON.stringify(activeFilters.sites)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.sites"
                    v-if="Object.values(optionsSites)"
                    :items="Object.values(optionsSites)"
                    item-text="name"
                    item-value="value"
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    @change="refreshSearch()"
                ></v-select>

                <div class="locations" v-if="currentFilters.sites == 'locations'">
                    <label class="description">{{ $t('user.filters.available_sites') }}</label>
                    <v-autocomplete
                        :class="{
                            filtered:
                                currentFilters.locations &&
                                typeof currentFilters.locations !== 'undefined' &&
                                Object.values(currentFilters.locations).length > 0,
                            changed: JSON.stringify(currentFilters.locations) != JSON.stringify(activeFilters.locations)
                        }"
                        hide-details
                        class="select"
                        v-model="currentFilters.locations"
                        v-if="Object.values(optionsLocations)"
                        :items="Object.values(optionsLocations)"
                        item-text="name"
                        item-value="id"
                        multiple
                        attach
                        dense
                        :no-data-text="$t('supervise.filter.no_results')"
                        :placeholder="$t('assets.filter.filter_by')"
                        return-object
                    >
                        <template v-slot:selection="{ item, index }">
                            {{ index == 0 ? '' : '' }}
                        </template>
                    </v-autocomplete>

                    <Tags :tags="currentFilters.locations" :backImage="'location_inactive_s30.svg'" :nameTag="'name'" value="id" :removable="true"></Tags>
                </div>

                <div class="custom" v-else-if="currentFilters.sites == 'groups'">
                    <label class="description">{{ $t('user.filters.available_territories') }}</label>
                    <v-autocomplete
                        :class="{
                            filtered: currentFilters.groups && typeof currentFilters.groups !== 'undefined' && Object.values(currentFilters.groups).length > 0,
                            changed: JSON.stringify(currentFilters.groups) != JSON.stringify(activeFilters.groups)
                        }"
                        hide-details
                        class="select"
                        v-model="currentFilters.groups"
                        v-if="Object.values(optionsGroups)"
                        :items="Object.values(optionsGroups)"
                        item-text="name"
                        item-value="id"
                        multiple
                        attach
                        dense
                        :no-data-text="$t('supervise.filter.no_results')"
                        :placeholder="$t('assets.filter.filter_by')"
                        return-object
                    >
                        <template v-slot:selection="{ item, index }">
                            {{ index == 0 ? '' : '' }}
                        </template>
                    </v-autocomplete>

                    <Tags :tags="currentFilters.groups" :backImage="'menu_inactive_s30.svg'" :nameTag="'name'" value="id" :removable="true"></Tags>
                </div>
            </div>

            <!-- TYPE STATE -->
            <div class="filter">
                <label>{{ $t('product.assigned_states') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.state && Object.values(currentFilters.state).length > 0,
                        changed: JSON.stringify(currentFilters.state) != JSON.stringify(activeFilters.state)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.state"
                    :items="Object.values(optionsStates)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.state" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <!-- TYPE DATE -->
            <div class="filter date">
                <label>{{ $t('supervise.checklists.table_headers.date') }}</label>
                <v-select
                    :class="{
                        filtered: currentFilters.type_date,
                        changed: JSON.stringify(currentFilters.type_date) != JSON.stringify(activeFilters.type_date)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.type_date"
                    :items="optionsDate"
                    item-text="name"
                    item-value="value"
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    @change="currentFilters.type_date == 'custom' ? (currentFilters.date = {}) : true"
                ></v-select>

                <div class="custom" v-if="currentFilters.type_date == 'custom'">
                    <!-- <v-text-field
						class="date start"
			            v-model="currentFilters.date.start_date"
			            placeholder="DD/MM/YYYY"
			            hide-details
			            @change="currentFilters.date.start_date = parseDate(currentFilters.date.start_date)"
		            ></v-text-field> -->
                    <div class="description">{{ $t('assets.filter.from') }}:</div>
                    <input class="date-input start" v-model="currentFilters.date.start_date" :max="minDate" type="date" @change="calcDate()" />

                    <div class="separator"></div>

                    <div class="description">{{ $t('assets.filter.to') }}:</div>
                    <input class="date-input end" v-model="currentFilters.date.end_date" :min="maxDate" type="date" @change="calcDate()" />
                </div>
            </div>
        </div>

        <FilterActions
            :showClearFiltersButton="showClearFiltersButton"
            :clearFiltersCallback="resetFilters"
            :filterCallback="applyFilters"
            :changesDetected="changesDetected"
        />
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'
import FilterActions from '../../../ui/FilterActions.vue'

const defaultFilters = {
    type_date: 'today'
}

export default {
    name: 'FilterLabel',
    components: {
        Tags,
        FilterActions
    },
    props: {
        isDisabled: { type: Boolean, default: false },
        applyFilter: { type: Boolean, default: false },
        pagination: { type: Object, default: false },
        sortBy: { type: undefined, default: false },
        sortType: { type: undefined, default: false },
        changePagination: { type: Boolean, default: false }
    },
    data() {
        return {
            currentFilters: structuredClone(defaultFilters),
            emptyFilters: structuredClone(defaultFilters),
            activeFilters: structuredClone(defaultFilters),
            // Filter variables
            changesDetected: false,
            maxDate: true,
            minDate: true
        }
    },
    computed: {
        filters() {
            return this.$store.getters['label/getFiltersActive']
        },
        optionsLocations() {
            return this.$store.getters['label/getFilterLocation']()
        },
        optionsGroups() {
            return this.$store.getters['label/getFilterGroup']()
        },
        optionsStates() {
            return this.$store.getters['label/getStatesForm']()
        },
        optionsDate() {
            return [
                { value: 'today', name: i18n.t('supervise.filter.today') },
                { value: 'yesterday', name: i18n.t('supervise.filter.yesterday') },
                { value: 'week', name: i18n.t('supervise.filter.week') },
                { value: 'month', name: i18n.t('supervise.filter.month') },
                { value: 'quarter', name: i18n.t('supervise.filter.quarter') },
                { value: 'custom', name: i18n.t('supervise.filter.custom') }
            ]
        },
        optionsSites() {
            return [
                { value: 'locations', name: i18n.t('user.sites') },
                { value: 'groups', name: i18n.t('user.filters.territories') }
            ]
        },

        showClearFiltersButton() {
            return JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)
        }
    },
    methods: {
        refreshSearch() {
            delete this.currentFilters.locations
            delete this.currentFilters.groups
        },
        updateFilters() {
            this.$emit('applyFilters', this.allFilters)
        },
        calcDate() {
            this.currentFilters.date && this.currentFilters.date.start_date
                ? (this.maxDate = moment(this.currentFilters.date.start_date).format('YYYY-MM-DD'))
                : true
            this.currentFilters.date && this.currentFilters.date.end_date
                ? (this.minDate = moment(this.currentFilters.date.end_date).format('YYYY-MM-DD'))
                : true
            // Force update object
            this.currentFilters = { ...this.currentFilters }
        },

        applyFilters() {
            this.$overlay.loading()

            const iApplyFilters = this.changesDetected ? 1 : 0
            let pagenum = this.pagination.page

            if (iApplyFilters) {
                this.$emit('resetPagination')
                pagenum = 1
            }

            var definitiveFilters = {}
            var locations = []
            var groups = []

            if (this.currentFilters.groups) {
                var filter = this.currentFilters.groups
                for (var index in filter) {
                    groups.push(filter[index].id)
                }
                definitiveFilters.groups = groups
            } else {
                definitiveFilters.groups = ''
            }
            if (this.currentFilters.locations) {
                var filter = this.currentFilters.locations
                for (var index in filter) {
                    locations.push(filter[index].id)
                }
                definitiveFilters.locations = locations
            } else {
                definitiveFilters.locations = ''
            }

            if (this.currentFilters.state && Object.values(this.currentFilters.state).length) {
                var filter = this.currentFilters.state
                definitiveFilters.state = []
                for (var index in filter) {
                    definitiveFilters.state.push(filter[index].id)
                }
                definitiveFilters.state = definitiveFilters.state.toString()
            } else definitiveFilters.state = ''

            if (this.currentFilters.type_date) {
                if (this.currentFilters.type_date != 'custom') {
                    definitiveFilters.start_date = this.currentFilters.type_date
                } else {
                    definitiveFilters.start_date = moment(this.currentFilters.date.start_date).format('YYYY-MM-DD')
                    definitiveFilters.end_date = moment(this.currentFilters.date.end_date).format('YYYY-MM-DD')
                }
            } else {
                definitiveFilters.start_date = ''
                definitiveFilters.end_date = ''
            }

            var self = this
            this.changesDetected = false

            this.$store.commit('label/setDefinitiveFilters', {
                locations: definitiveFilters.locations,
                groups: definitiveFilters.groups,
                states: definitiveFilters.state,
                start_date: definitiveFilters.start_date,
                end_date: definitiveFilters.end_date
            })
            this.$store
                .dispatch('label/loadList', {
                    locations: definitiveFilters.locations,
                    groups: definitiveFilters.groups,
                    states: definitiveFilters.state,
                    start_date: definitiveFilters.start_date,
                    end_date: definitiveFilters.end_date,
                    pagenum: pagenum,
                    number_items: this.pagination.itemsPerPage,
                    sortBy: this.sortBy,
                    sortType: this.sortType
                })
                .then(function () {
                    // self.activeFilters = { ...self.currentFilters };
                    self.activeFilters = JSON.parse(JSON.stringify(self.currentFilters))
                    self.$store.commit('label/setFiltersActive', structuredClone(self.activeFilters))

                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                    if (JSON.stringify(self.activeFilters) != JSON.stringify(self.emptyFilters)) {
                        self.$emit('reset', true)
                    } else {
                        self.$emit('reset', false)
                    }
                })
        },
        resetFilters() {
            this.$emit('resetPagination')
            var self = this
            this.$overlay.loading()
            this.currentFilters = { ...this.emptyFilters }
            this.activeFilters = { ...this.emptyFilters }
            this.changesDetected = false
            this.$store.commit('label/setFiltersActive', this.currentFilters)
            this.$store.dispatch('label/loadList', { start_date: 'today' }).then(function () {
                self.$overlay.hide()
                self.$emit('resetPageDatatable')
            })
        },
        handleChangePagination(page) {
            this.$overlay.loading()

            let self = this
            let pagenum = page ? page : this.pagination.page
            let definitiveFilters = {}
            let locations = []
            let groups = []

            if (this.activeFilters.groups) {
                var filter = this.activeFilters.groups
                for (var index in filter) {
                    groups.push(filter[index].id)
                }
                definitiveFilters.groups = groups
            } else {
                definitiveFilters.groups = ''
            }
            if (this.activeFilters.locations) {
                var filter = this.activeFilters.locations
                for (var index in filter) {
                    locations.push(filter[index].id)
                }
                definitiveFilters.locations = locations
            } else {
                definitiveFilters.locations = ''
            }

            if (this.activeFilters.state && Object.values(this.activeFilters.state).length) {
                var filter = this.activeFilters.state
                definitiveFilters.state = []
                for (var index in filter) {
                    definitiveFilters.state.push(filter[index].id)
                }
                definitiveFilters.state = definitiveFilters.state.toString()
            } else definitiveFilters.state = ''

            if (this.activeFilters.type_date) {
                if (this.activeFilters.type_date != 'custom') {
                    definitiveFilters.start_date = this.activeFilters.type_date
                } else {
                    definitiveFilters.start_date = moment(this.activeFilters.date.start_date).format('YYYY-MM-DD')
                    definitiveFilters.end_date = moment(this.activeFilters.date.end_date).format('YYYY-MM-DD')
                }
            } else {
                definitiveFilters.start_date = ''
                definitiveFilters.end_date = ''
            }

            this.$store
                .dispatch('label/loadList', {
                    locations: definitiveFilters.locations,
                    groups: definitiveFilters.groups,
                    states: definitiveFilters.state,
                    start_date: definitiveFilters.start_date,
                    end_date: definitiveFilters.end_date,
                    pagenum: pagenum,
                    number_items: this.pagination.itemsPerPage,
                    sortBy: this.sortBy,
                    sortType: this.sortType
                })
                .then(function () {
                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                    let reset = JSON.stringify(self.activeFilters) != JSON.stringify(self.emptyFilters)
                    self.$emit('reset', reset)
                })
        }
    },
    mounted() {
        //  desarrollo para los emails con fecha
        if (this.$route.params.startDate) {
            var startDate = this.$route.params.startDate
            var endDate = this.$route.params.endDate
            this.activeFilters = {
                type_date: 'custom',
                date: {
                    start_date: startDate,
                    end_date: endDate
                }
            }
            this.currentFilters = {
                type_date: 'custom',
                date: {
                    start_date: startDate,
                    end_date: endDate
                }
            }
            //this.applyFilters()
        } else {
            if (this.applyFilter) {
                const storeActiveFilters = this.$store.getters['label/getFiltersActive']
                this.currentFilters = storeActiveFilters
                this.activeFilters = storeActiveFilters

                this.applyFilters()
            }
        }
    },
    created() {},
    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.activeFilters)) {
                    this.changesDetected = true
                } else {
                    this.changesDetected = false
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
// Busca en assets :^)
</style>
