var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-box"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('assets.filter.filter_data')))]),_c('div',{staticClass:"filter-items"},[_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('user.filters.search_by')))]),(Object.values(_vm.optionsSites))?_c('v-select',{staticClass:"select",class:{
                        filtered: _vm.currentFilters.sites,
                        changed: JSON.stringify(_vm.currentFilters.sites) != JSON.stringify(_vm.activeFilters.sites)
                    },attrs:{"hide-details":"","items":Object.values(_vm.optionsSites),"item-text":"name","item-value":"value","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by')},on:{"change":function($event){return _vm.refreshSearch()}},model:{value:(_vm.currentFilters.sites),callback:function ($$v) {_vm.$set(_vm.currentFilters, "sites", $$v)},expression:"currentFilters.sites"}}):_vm._e(),(_vm.currentFilters.sites == 'locations')?_c('div',{staticClass:"locations"},[_c('label',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('user.filters.available_sites')))]),(Object.values(_vm.optionsLocations))?_c('v-autocomplete',{staticClass:"select",class:{
                            filtered:
                                _vm.currentFilters.locations &&
                                typeof _vm.currentFilters.locations !== 'undefined' &&
                                Object.values(_vm.currentFilters.locations).length > 0,
                            changed: JSON.stringify(_vm.currentFilters.locations) != JSON.stringify(_vm.activeFilters.locations)
                        },attrs:{"hide-details":"","items":Object.values(_vm.optionsLocations),"item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var item = ref.item;
                        var index = ref.index;
return [_vm._v("\n                            "+_vm._s(index == 0 ? '' : '')+"\n                        ")]}}],null,false,2353096790),model:{value:(_vm.currentFilters.locations),callback:function ($$v) {_vm.$set(_vm.currentFilters, "locations", $$v)},expression:"currentFilters.locations"}}):_vm._e(),_c('Tags',{attrs:{"tags":_vm.currentFilters.locations,"backImage":'location_inactive_s30.svg',"nameTag":'name',"value":"id","removable":true}})],1):(_vm.currentFilters.sites == 'groups')?_c('div',{staticClass:"custom"},[_c('label',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('user.filters.available_territories')))]),(Object.values(_vm.optionsGroups))?_c('v-autocomplete',{staticClass:"select",class:{
                            filtered: _vm.currentFilters.groups && typeof _vm.currentFilters.groups !== 'undefined' && Object.values(_vm.currentFilters.groups).length > 0,
                            changed: JSON.stringify(_vm.currentFilters.groups) != JSON.stringify(_vm.activeFilters.groups)
                        },attrs:{"hide-details":"","items":Object.values(_vm.optionsGroups),"item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var item = ref.item;
                        var index = ref.index;
return [_vm._v("\n                            "+_vm._s(index == 0 ? '' : '')+"\n                        ")]}}],null,false,2353096790),model:{value:(_vm.currentFilters.groups),callback:function ($$v) {_vm.$set(_vm.currentFilters, "groups", $$v)},expression:"currentFilters.groups"}}):_vm._e(),_c('Tags',{attrs:{"tags":_vm.currentFilters.groups,"backImage":'menu_inactive_s30.svg',"nameTag":'name',"value":"id","removable":true}})],1):_vm._e()],1),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('product.assigned_states')))]),_c('v-autocomplete',{staticClass:"select",class:{
                        filtered: _vm.currentFilters.state && Object.values(_vm.currentFilters.state).length > 0,
                        changed: JSON.stringify(_vm.currentFilters.state) != JSON.stringify(_vm.activeFilters.state)
                    },attrs:{"hide-details":"","items":Object.values(_vm.optionsStates),"item-text":"name","item-value":"id","multiple":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_vm._v("\n                        "+_vm._s(index == 0 ? '' : '')+"\n                    ")]}}]),model:{value:(_vm.currentFilters.state),callback:function ($$v) {_vm.$set(_vm.currentFilters, "state", $$v)},expression:"currentFilters.state"}}),_c('Tags',{attrs:{"tags":_vm.currentFilters.state,"nameTag":'name',"value":"id","removable":true}})],1),_c('div',{staticClass:"filter date"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.checklists.table_headers.date')))]),_c('v-select',{staticClass:"select",class:{
                        filtered: _vm.currentFilters.type_date,
                        changed: JSON.stringify(_vm.currentFilters.type_date) != JSON.stringify(_vm.activeFilters.type_date)
                    },attrs:{"hide-details":"","items":_vm.optionsDate,"item-text":"name","item-value":"value","dense":"","no-data-text":_vm.$t('supervise.filter.no_results')},on:{"change":function($event){_vm.currentFilters.type_date == 'custom' ? (_vm.currentFilters.date = {}) : true}},model:{value:(_vm.currentFilters.type_date),callback:function ($$v) {_vm.$set(_vm.currentFilters, "type_date", $$v)},expression:"currentFilters.type_date"}}),(_vm.currentFilters.type_date == 'custom')?_c('div',{staticClass:"custom"},[_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('assets.filter.from'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentFilters.date.start_date),expression:"currentFilters.date.start_date"}],staticClass:"date-input start",attrs:{"max":_vm.minDate,"type":"date"},domProps:{"value":(_vm.currentFilters.date.start_date)},on:{"change":function($event){return _vm.calcDate()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentFilters.date, "start_date", $event.target.value)}}}),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('assets.filter.to'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentFilters.date.end_date),expression:"currentFilters.date.end_date"}],staticClass:"date-input end",attrs:{"min":_vm.maxDate,"type":"date"},domProps:{"value":(_vm.currentFilters.date.end_date)},on:{"change":function($event){return _vm.calcDate()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentFilters.date, "end_date", $event.target.value)}}})]):_vm._e()],1)]),_c('FilterActions',{attrs:{"showClearFiltersButton":_vm.showClearFiltersButton,"clearFiltersCallback":_vm.resetFilters,"filterCallback":_vm.applyFilters,"changesDetected":_vm.changesDetected}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }